import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { Error, Success, Loader } from "../feedback";
import { Box, Row } from "../util";

import Inbox_Icon from "../../images/graphics/icons/Icon_Message.svg";

export const PartnerInterestForm = ({ centerForm }) => {
  const [fields, setInputs] = useState({});
  let [success, setForm] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");
  // let [recaptchaChecked, setRecaptchaChecked] = useState(false);

  const recaptchaRef = React.createRef();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      createPartner(fields);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((fields) => ({
      ...fields,
      [event.target.name]: event.target.value,
    }));
  };

  const onChange = (value) => {
    if (value) {
      // setRecaptchaChecked(true);
      setError("");
    }
  };

  function createPartner(details) {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      setError("Please complete the Google reCAPTCHA.");
      return;
    }

    setError("");
    setLoading(true);

    var xhttp = new XMLHttpRequest();
    var url = process.env.GATSBY_SITE_API  + "/partner/register";

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
    xhttp.responseType = "json";

    xhttp.onreadystatechange = function() {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          setTimeout(() => {
            setForm(true);
            setLoading(false);
          }, 2000);
        } else {
          setError(
            xhttp.response && xhttp.response.message
              ? xhttp.response.message
              : "Sorry! Something went wrong. Please refresh and try again. If the issue persists, please contact us at partners@flexitime.works and we'll help you get started."
          );
          setLoading(false);
        }
      }
    };

    xhttp.onerror = function(e) {
      setError(
        xhttp.response && xhttp.response.message
          ? xhttp.response.message
          : "Sorry! Something went wrong. Please refresh and try again. If the issue persists, please contact us at partners@flexitime.works and we'll help you get started."
      );
      setLoading(false);
    };

    xhttp.send(JSON.stringify(details));
  }

  return (
    <>
      <Error message={error} />
      <Loader show={loading} white />
      {!success && (
        <>
          <form
            className={`${loading ? "-isLoading" : ""}`}
            onSubmit={handleSubmit}
          >
            <Row stackGap={40}>
              <Box size={50}>
                <span className="input-title">Full Name</span>
                <input
                  type="text"
                  placeholder="Full Name"
                  name="contact_name"
                  aria-label="Enter Full Name"
                  value={fields.contact_name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Box>
              <Box size={50}>
                <span className="input-title">Email Address</span>
                <input
                  type="email"
                  placeholder="Email Address"
                  name="contact_email"
                  aria-label="Enter Email Address"
                  value={fields.contact_email || ""}
                  onChange={handleInputChange}
                  required
                />
              </Box>
            </Row>
            <Row stackGap={40}>
              <Box size={50}>
                <span className="input-title">Company Name</span>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="company_name"
                  aria-label="Enter Company Name"
                  value={fields.company_name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Box>
              <Box size={50}>
                <span className="input-title">Contact Number</span>
                <input
                  type="phone"
                  placeholder="Contact Number"
                  name="contact_phone"
                  aria-label="Enter Contact Number"
                  value={fields.contact_phone || ""}
                  onChange={handleInputChange}
                  required
                />
              </Box>
            </Row>
            <Box size={100}>
              <span className="input-title">Company Website</span>
              <input
                type="text"
                placeholder="Company Website"
                name="company_website"
                aria-label="Enter Company Website"
                value={fields.company_website || ""}
                onChange={handleInputChange}
                css={{ marginBottom: "20px" }}
                required
              />
            </Box>
            <ReCAPTCHA
              className="-center"
              css={{ display: "table" }}
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHASITEKEY}
              onChange={onChange}
            />
            <button className="primary" type="submit">
              Register Your Interest
            </button>
          </form>
        </>
      )}
      <Success
        show={success}
        icon={Inbox_Icon}
        title="Details Submitted"
        message="Thanks for sending us your details. We will get back to
          you with more information soon."
        customClass="partner-success"
        centerAlign
      />
    </>
  );
};
